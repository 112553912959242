<script>
/**
 * Dashboard component
 */
export default {
  data() {
    return {
      trainingData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "title", sortable: true, label: "Title" },
        { key: "description", label: "Description" },
        { key: "url", label: "Url" },
        { key: "category", label: "Category" },
        { key: "action" },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.profileData.length;
    },
  },
  mounted() {
    this.getprofileData();
    // Set the initial number of items
    this.totalRows = this.profileData.length;
  },
  methods: {
    changeProfile() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.getters.client
            .post("/supportdocuments/", this.data)
            .then((response) => {
              console.log(response);
              this.$swal({
                position: "top-end",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push({ name: "Documents" });
            });
        }
      });
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">PROFILE SETTINGS</h4>

          <!-- <img
            class="img-thumbnail rounded avatar-lg"
            alt="200x200"
            src="@/assets/images/users/avatar-2.jpg"
            data-holder-rendered="true"
          /> -->
          <button type="button" class="btn btn-primary float-right">
            Change Photo
          </button>
          <p class="card-title-desc">
            Update the account information according to your requirement
          </p>
          <h4 class="card-title ">ACCOUNT INFORMATION</h4>
          <form class="form-horizontal" role="form">
            <b-form-group
              id=""
              label-cols-sm="2"
              label-cols-lg="2"
              label="Title"
              label-for="text"
            >
              <b-form-input for="text"></b-form-input>
            </b-form-group>

            <b-form-group
              id=""
              label-cols-sm="2"
              label-cols-lg="2"
              label="Email"
              label-for="text"
            >
              <b-form-input for="text"></b-form-input>
            </b-form-group>
            <h4 class="card-title ">CHANGE PASSWORD</h4>
            <b-form-group
              id=""
              label-cols-sm="2"
              label-cols-lg="2"
              label="Current Password"
              label-for="pwd"
            >
              <b-form-input type="password"></b-form-input>
            </b-form-group>
            <b-form-group
              id=""
              label-cols-sm="2"
              label-cols-lg="2"
              label="New Password"
              label-for="pwd"
            >
              <b-form-input type="password"></b-form-input>
            </b-form-group>
            <b-form-group
              id=""
              label-cols-sm="2"
              label-cols-lg="2"
              label="Confirm Password"
              label-for="pwd"
            >
              <b-form-input type="password"></b-form-input>
            </b-form-group>
            <div class="text-center mt-4">
              <button
                type="button"
                @click="changeProfile"
                class="btn btn-primary"
              >
                Submit Changes
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->

    <!-- end col -->
  </div>
</template>
