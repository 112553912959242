<script>
export default {
  data() {
    return {
      orderData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 25, 50],
      statusOptions: ["Open", "Archived", "Canceled"],
      statusPage: "Status",
      paymentPage: "Payment Status",
      paymentOptions: [
        "Paid",
        "Unpaid",
        "Authorized",
        "Refunded",
        "Partially paid",
        "Partially refunded",
      ],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,

      fields: [
        { key: "order_number", sortable: true, label: "Order" },
        { key: "created_at", sortable: true, label: "Date" },
        { key: "delivery_date", sortable: true, label: "Delivery Date" },
        { key: "delivery_service", sortable: true, label: "Service" },
        { key: "total_items", label: "Items" },
        { key: "status", label: "Status" },
        { key: "payment_terms", label: "Payment Status" },
        { key: "total_item_cost", label: "Total Amount" }
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.orderData.length;
    },
  },
  mounted() {
    this.getOrderData();
    // Set the initial number of items
    this.totalRows = this.orderData.length;
  },
  methods: {
    myRowClickHandler(event) {
      this.$router.push({ name: "OrderDetails", params: { id: event.id } });
    },
    goToCreateOrders() {
      this.$router.push({ name: "CreateOrders" });
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    async manageOrderStatus(ORDER_ITEM, STATUS) {
      let loader = this.$loading.show({
        loader: "dots",
      });

      try {
        await this.$store.getters.client.patch(`/order/supplier-assign/${ORDER_ITEM.id}/`, { status: STATUS });
        this.getOrderData()
      } catch (error) {
        console.error(error);
      } finally {
        loader.hide();
      }
    },
    getDropdownVariant(status) {
      switch (status) {
        case 'assign-supplier':
          return 'warning'; // You can change this to the desired variant
        case 'pending':
          return 'warning'; // Change this to the appropriate variant
        case 'accepted':
          return 'info'; // Change this to the appropriate variant
        case 'rejected':
          return 'danger'; // Change this to the appropriate variant
        case 'dispatched':
          return 'success'; // Change this to the appropriate variant
        case 'net30':
          return 'outline-warning';
        case 'paid':
          return 'outline-success';
        default:
          return 'secondary'; // Default variant
      }
    },
    async getOrderData() {
      let loader = this.$loading.show({
        loader: "dots",
      });

      try {
        const response = await this.$store.getters.client.get("/order/supplier-assign/");
        const orderData = response.data.results;
        orderData.forEach(item => {
          item.order_items = item.order_items.map(ele => {
            return {
              ...ele,
              item_total_cost: Number(ele.item_cost),
            };
          });
          const ITEM_COST = item.order_items.reduce(
            (total, item) => total + Number(item.item_total_cost),
            0
          );
          const TOTAL_VAT = item.order_items.reduce(
            (total, item) => total + Number(item.vat),
            0
          );
          const DELIVERY_COST = Number(item.delivery_cost);
          const DELIVERY_VAT = DELIVERY_COST / 5
          item.total_item_cost = ITEM_COST + TOTAL_VAT + DELIVERY_COST + DELIVERY_VAT
        });
        this.orderData = orderData;
      } catch (error) {
        console.error(error);
      } finally {
        loader.hide();
      }
    }
  },
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body pt-0">
          <b-tabs nav-class="nav-tabs-custom">
            <b-tab title-link-class="p-3">
              <template v-slot:title>
                <a class="font-weight-bold active">All</a>
              </template>

              <div class="row">
                <div class="col-lg-4 pt-3 pr-0 col-md-1">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <b-form-input v-model="filter" type="search" placeholder="Search"
                      class="form-control form-control-sm ml-2 float right">
                    </b-form-input>
                  </div>
                </div>
                <div class="col-lg-1 pt-3 pr-0 col-md-1">
                  <b-form-select v-model="perPage" size="sm" :options="pageOptions">
                    <option selected>Entries</option>
                  </b-form-select>
                </div>
                <div class="col-lg-2 pt-3 pr-0 col-md-1">
                  <b-form-select v-model="statusPage" size="sm" :options="statusOptions">
                    <option selected>Status</option>
                  </b-form-select>
                </div>
                <div class="col-lg-2 pt-3 pl-0 col-md-1">
                  <b-form-select v-model="paymentPage" size="sm" :options="paymentOptions">
                    <option selected>Payment Status</option>
                  </b-form-select>
                </div>
                <div class="col-lg-2 pt-3 pl-0 col-md-1">
                  <button @click="toggleRightSidebar"
                    class="btn btn-sm btn-primary right-bar-toggle waves-effect toggle-right">
                    More Filters
                  </button>
                </div>
                <div class="col-lg-1 pt-2 ">
                  <b-dropdown size="sm" class="m-2">
                    <template v-slot:button-content>
                      Sort
                      <i class="fas fa-sort"></i>
                    </template>
                    <b-dropdown-item-button>Time</b-dropdown-item-button>
                    <b-dropdown-item-button>Price</b-dropdown-item-button>
                    <b-dropdown-item-button>Quantity</b-dropdown-item-button>
                  </b-dropdown>
                </div>
              </div>

              <!-- End search -->

              <div class="table-responsive">
                <b-table hover class="table-centered" :items="orderData" :fields="fields" responsive="sm"
                  :per-page="perPage" :current-page="currentPage" @row-clicked="myRowClickHandler">
                  <template v-slot:cell(id)="row">
                    <span>#{{ row.item.id }}</span>
                  </template>
                  <template v-slot:cell(created_at)="row">
                    <span>{{ row.item.created_at | moment("DD MMM, YYYY") }}</span>
                  </template>
                  <template v-slot:cell(delivery_date)="row">
                    <span>{{ row.item.delivery_date | moment("DD MMM, YYYY") }}</span>
                  </template>
                  <template v-slot:cell(total_items)="row">
                    {{ row.item.order_items.length }} items
                  </template>
                  <template v-slot:cell(status)="row">
                    <b-dropdown size="sm" class="m-2" :variant="getDropdownVariant(row.item.status)"
                      :disabled="row.item.status === 'accepted' || row.item.status === 'rejected'">
                      <template v-slot:button-content>
                        <span v-if="row.item.status === 'assign-supplier'">PENDING</span>
                        <span v-if="row.item.status === 'pending'">PENDING</span>
                        <span v-if="row.item.status === 'accepted'">ACCEPTED</span>
                        <span v-if="row.item.status === 'rejected'">REJECTED</span>
                        <span v-if="row.item.status === 'dispatched'">DISPATCHED</span>
                      </template>
                      <b-dropdown-item-button
                        @click="manageOrderStatus(row.item, 'accepted')">ACCEPT</b-dropdown-item-button>
                      <b-dropdown-item-button
                        @click="manageOrderStatus(row.item, 'rejected')">REJECTED</b-dropdown-item-button>
                    </b-dropdown>
                  </template>
                  <template v-slot:cell(total_item_cost)="row">
                    £{{ row.item.total_item_cost.toFixed(2) }}
                  </template>
                  <template v-slot:cell(payment_terms)="row">
                    <b-button size="sm" :variant="getDropdownVariant(row.item.payment_terms)">
                      <span v-if="row.item.payment_terms === 'net30'">NET 30</span>
                      <span v-if="row.item.payment_terms === 'paid'">PAID</span>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.add-button {
  margin-bottom: 27px;
  margin-left: 14px;
}
</style>
