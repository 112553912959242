import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueMask from "v-mask";
import VueYoutube from "vue-youtube";
import VeeValidate from "vee-validate";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import vco from "v-click-outside";
import router from "./router";
import store from "@/state/store";
import i18n from "./i18n";
import "@/assets/scss/app.scss";
import VueRouter from "vue-router";

Vue.config.productionTip = false;
export const EventBus = new Vue();

Vue.use(VueRouter);
Vue.use(require('vue-moment'));
Vue.config.productionTip = false;
Vue.use(Loading);
Vue.use(VueYoutube);
Vue.use(BootstrapVue);
Vue.use(VeeValidate, { inject: true, fieldsBagName: "veeFields" });
Vue.use(vco);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(require("vue-chartist"));
const routes = [
  { path: "/dashboard", component: App },
];

Vue.component("apexchart", VueApexCharts);
new Vue({
  router,
  routes,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
