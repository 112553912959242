import Login from "./login";

// import middlewares
export default [
  {
    path: "/",
    component: Login,
    name: "Login",
  }
];
