<script>
export default {
  data() {
    return {
      orderData: {},
      id: null,
      messageBox: false,
      message: '',
      dispatchData: {
        url: '',
        track_id: '',
        comments: ''
      },
      dispatchOption: 'all',
      selectedItems: [],
      selectedTracking: null,
      trackModal: false,
    };
  },
  mounted() {
    this.getOrderData();
  },
  methods: {
    getDropdownVariant(status) {
      switch (status) {
        case 'assign-supplier':
          return 'warning';
        case 'pending':
          return 'warning';
        case 'accepted':
          return 'info';
        case 'rejected':
          return 'danger';
        case 'dispatched':
          return 'success';
        default:
          return 'secondary';
      }
    },
    getOrderData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`/order/supplier-assign/${this.id}/`)
        .then((response) => {
          this.orderData = response.data;
          const SUB_TOTAL = this.orderData.order_items.reduce(
            (total, item) => total + Number(item.item_cost),
            0
          );
          const ITEM_VAT = this.orderData.order_items.reduce(
            (total, item) => total + Number(item.vat),
            0
          );
          const DELIVERY_VAT = Number(this.orderData.delivery_cost) / 5

          const TOTAL_VAT = (ITEM_VAT + DELIVERY_VAT).toFixed(2)

          this.orderData = {
            ...this.orderData,
            sub_total: SUB_TOTAL.toFixed(2),
            total_vat: TOTAL_VAT,
            total: Number(SUB_TOTAL) + Number(TOTAL_VAT) + Number(this.orderData.delivery_cost)
          }
          loader.hide();
        }).catch((error) => {
          loader.hide();
          alert(error)
        });

    },
    sendMessage() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .patch(`/order/supplier-assign/${this.id}/`, { comments: this.message })
        .then(() => {
          this.messageBox = false,
            this.message = '',
            this.getOrderData()
          loader.hide();
        }).catch((error)=>{
          loader.hide();
          alert(error)
        });
    },
    itemsForTracking() {
      if (this.dispatchOption === 'all') {
        this.selectedItems = []
        this.orderData.order_items.forEach(element => {
          if (element.tracking_details.length === 0) {
            this.selectedItems.push(element.order_item.id)
          }
        });
      }
      else {
        return this.selectedItems
      }
    },
    updateTracking(details) {
      console.log(details)

      this.selectedTracking = details;
      this.trackModal = true
    },

    async updateTrackingDetails() {
      const trackData = this.selectedTracking
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        await this.$store.getters.client.patch(`/order/track-order/${trackData.id}/`, trackData)
        this.getOrderData()
      } catch (error) {
        alert(error)
      }
      finally {
        this.trackModal = false
        loader.hide()
      }
    },
    async addTracking() {
      this.itemsForTracking()
      let formData = {
        ...this.dispatchData,
        order_item: this.selectedItems,
        order: this.orderData.order_id
      }
      if (this.selectedItems.length > 0) {
        let loader = this.$loading.show({
          loader: "dots",
        });
        try {
          await this.$store.getters.client.post(`/order/track-order/`, formData)
          this.$store.getters.client.patch(`/order/supplier-assign/${this.orderData.id}/`, { status: 'dispatched' });
          this.getOrderData()
          this.selectedItems = null
        } catch (error) {
          alert(error)
        }
        finally {
          loader.hide()
        }
      }
      else {
        alert('All items are already dispatched')
      }
    },
  },
  created() {
    this.id = this.$route.params.id
  }
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <b-alert show :variant="getDropdownVariant(orderData.status)">
        <p>
          <i class="mdi mdi-alert-circle-outline mr-2"></i><span>Order <b>{{ orderData.order_number }}</b> is <span
              class="text-uppercase">{{ orderData.status }}</span></span>
        </p>
        <p class="ml-4">Before commencing the job, it's important to ensure the order has been accepted. Digital Press
          holds no liability if the job commences without the order being accepted.</p>
      </b-alert>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="bg-light">
                <tr>
                  <th>Product</th>
                  <th>Description</th>
                  <th>Artwork</th>
                  <th>Unit Price</th>
                  <th>Quantity</th>
                  <th class="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="items in orderData.order_items" :key="items.id">
                  <td>
                    <!-- {{ items.quotation_images }} -->
                    <img v-if="items.quotation_images" :src="items.quotation_images" alt="product-img" title="product-img"
                      class="avatar-md" />
                  </td>
                  <td>
                    <h5 class="font-size-14 text-truncate">
                      <a href="ecommerce-product-detail.html" class="text-dark">{{ items.order_item.name }}</a>
                    </h5>
                    <template v-if="items.order_item.variant.length > 0">
                      <div v-for="varient in items.order_item.variant" :key="varient.id">
                        <div>{{ varient.attribute.name }} : <b>{{ varient.attribute_value.name }}</b></div>
                      </div>
                    </template>
                    <template v-else>
                      <div v-html="items.order_item.description"></div>
                    </template>
                  </td>
                  <td><a target="_blank" :href="items.artwork_proofing">Download</a></td>
                  <td><span v-if="items.unit_price">&#163;{{ items.unit_price }}</span> <span v-else>Not Applicable</span>
                  </td>
                  <td>{{ items.order_item.qty_ordered }}</td>
                  <td class="text-right">&#163;{{ items.item_cost }}</td>
                </tr>
                <tr class="bg-light text-right">
                  <th scope="row" colspan="5">Sub Total :</th>

                  <td>&#163;{{ orderData.sub_total }}</td>
                </tr>
                <tr class="bg-light text-right">
                  <th scope="row" colspan="5">Shipping Charge :</th>
                  <td>&#163;{{ orderData.delivery_cost }}</td>
                </tr>
                <tr class="bg-light text-right">
                  <th scope="row" colspan="5">VAT :</th>
                  <td>&#163;{{ orderData.total_vat }}</td>
                </tr>
                <tr class="bg-light text-right">
                  <th scope="row" colspan="5">Total :</th>

                  <td>&#163;{{ orderData.total.toFixed(2) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="card">
        <div class="card-header bg-transparent border-bottom">
          <span class="h5 mb-0">Comment</span>

          <a href="javascript:void(0)" role="button" v-if="!messageBox" @click="messageBox = true"
            class="float-right">Add</a>
          <a href="javascript:void(0)" role="button" v-if="messageBox" @click="messageBox = false"
            class="float-right">Cancel</a>
          <b-form class="mt-2" v-if="messageBox">
            <b-form-textarea id="note" v-model="message" placeholder="Write note" class="mb-2"></b-form-textarea>
            <b-button class="w-100" size="sm" variant="success" :disabled="message === ''"
              @click="sendMessage">Send</b-button>
          </b-form>
          <hr>
          <h6 class="text-muted mt-3">
            <span v-if="orderData.comments">{{ orderData.comments }}</span>
            <span v-else>No Notes</span>
          </h6>
        </div>
      </div>
      <b-card title="Tracking details">
        <div v-for="(item, index) in orderData.order_items" :key="index" class="border-top py-2">
          <b>{{ item.order_item.name }}</b> <br>
          <div v-for="track in item.tracking_details" :key="track.id">
            <b-button variant="success" size="sm" class="float-right" @click="updateTracking(track)">Edit</b-button>
            <b>Link :</b> <a :href="track.url">{{ track.url }}</a> <br>
            <b>ID : </b> {{ track.track_id }}
            <div class="bg-light"><b>Comment : </b> {{ track.comments }}</div>
          </div>
        </div>
      </b-card>
    </div>
    <div class="col-lg-4">
      <div class="card">
        <div class="card-header bg-transparent border-bottom">
          <h5 class="mb-0  ">
            Delivery Details
          </h5>
          <hr>
          <label for="">SHIPPING ADDRESS</label>
          <h6 class="text-muted">{{ orderData.delivery_address }}</h6>
          <hr>
          <label for="">DELIVERY TYPE</label>
          <h6 class="text-muted">{{ orderData.delivery_service }}</h6>
          <hr>
          <label for="">DELIVERY DATE</label>
          <h6 class="text-muted">{{ orderData.delivery_date | moment("DD MMM, YYYY") }}</h6>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <b-card title="Dispatch">
        <label>Please select the items you are dispatching</label>
        <hr>
        <b-form>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group id="radio-group-2" v-model="dispatchOption" :aria-describedby="ariaDescribedby"
              name="radio-sub-component">
              <b-form-radio value="all">All Products</b-form-radio>
              <b-form-radio value="select" :disabled="orderData.order_items.length < 2">Select Products</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <b-form-group label="Select Products" v-slot="{ ariaDescribedby }" v-if="dispatchOption === 'select'">
            <b-form-checkbox-group stacked id="selectDispatch" v-model="selectedItems" :aria-describedby="ariaDescribedby"
              name="dispatch">
              <b-form-checkbox :disabled="product.tracking_details.length > 0" :value="product.order_item.id"
                v-for="product in orderData.order_items" :key="product.id">{{
                  product.order_item.name }} <span v-if="product.tracking_details.length > 0"
                  class="text-danger">Dispatched</span></b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
          <hr>
          <b-form-group label="URL">
            <b-form-input type="url" required placeholder="Tracking url" v-model="dispatchData.url"></b-form-input>
          </b-form-group>
          <b-form-group label="Tracking ID">
            <b-form-input type="text" required placeholder="Tracking ID" v-model="dispatchData.track_id"></b-form-input>
          </b-form-group>
          <b-form-group label="Comment">
            <b-form-textarea type="text" required placeholder="Comment" v-model="dispatchData.comments"></b-form-textarea>
          </b-form-group>
          <b-button variant="primary" :disabled="dispatchData.url === '' || dispatchData.track_id === ''" size="sm"
            type="button" @click="addTracking" class="w-100">Add Details</b-button>
        </b-form>
      </b-card>
    </div>
    <b-modal v-model="trackModal" hide-footer title="Update tracking details">
      <b-form v-if="selectedTracking">
        <b-row>
          <b-col cols="12">
            <b-form-group label="URL">
              <b-form-input type="text" v-model="selectedTracking.url"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="URL">
              <b-form-input type="text" v-model="selectedTracking.track_id"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="URL">
              <b-form-textarea v-model="selectedTracking.comments"></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-right">
            <b-button class="w-100" type="button" variant="outline-success"
              @click="updateTrackingDetails">UPDATE</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
