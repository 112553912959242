import ProfileManagement from "./index";


// import middlewares
export default [
  {
    path: "/profile-management",
    component: ProfileManagement,
    name: "ProfileManagement",
  },

];
