<script>
import Stat from "./widget";
import RevenueAnalytics from "./revenue";
export default {
  components: { Stat, RevenueAnalytics },
  data() {
    return {
      title: "Dashboard",
      analyticData: null,
      items: [
        {
          text: "Nazox",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.getAnalytics()
  },
  methods: {
    async getAnalytics() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        const result = await this.$store.getters.client.get(`/order/supplier-dashboard/`)
        console.log(result)
        this.analyticData = result.data
      } catch (error) {
        console.error(error)
      } finally {
        loader.hide()
      }
    }
  }
};
</script>

<template>
  <b-row>
    <b-col cols="8">
      <RevenueAnalytics />
    </b-col>
    <b-col cols="4">
      <b-card title="Daily Sales"></b-card>
      <b-card title="Weekly Sales">
        <div class="table-responsive mt-4">
          <table class="table table-hover mb-0 table-centered table-nowrap">
            <tbody>
              <tr v-for="(item, index) in analyticData.weekly_sale" :key="index">
                <td>
                  <h5 class="font-size-14 mb-0">{{ item.weekly | moment("DD MMM, YYYY") }}</h5>
                </td>
                <td>
                  <p class="text-muted mb-0">{{ item.count }}</p>
                </td>
                <td>
                  <p class="text-muted mb-0">&#163;{{ item.sum.toFixed(2) }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
      <b-card title="Monthly Sales">
        <div class="table-responsive mt-4">
          <table class="table table-hover mb-0 table-centered table-nowrap">
            <tbody>
              <tr v-for="(item, index) in analyticData.monthly_sale" :key="index">
                <td>
                  <h5 class="font-size-14 mb-0">{{ item.monthly | moment("MMM, YYYY") }}</h5>
                </td>
                <td>
                  <p class="text-muted mb-0">{{ item.count }}</p>
                </td>
                <td>
                  <p class="text-muted mb-0">&#163;{{ item.sum.toFixed(2) }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-col>
    <div class="col-xl-12">
      <Stat :analytic="analyticData" />
    </div>
  </b-row>
</template>
