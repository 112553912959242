<script>
/**
 * Widget Component
 */
export default {
  props:{
    analytic: Object,
  },
  data() {
    return {
      statData: [
        {
          title: "Number of Sales",
          icon: "ri-stack-line",
          value: this.analytic.total_no_of_orders,
          // subvalue: " 2.4% ",
        },
        {
          title: "Sales Revenue",
          icon: "ri-store-2-line",
          value: "$ 38452",
          subvalue: " 2.4% ",
        },
        {
          title: "Average Price",
          icon: "ri-briefcase-4-line",
          value: "$ 15.4",
          subvalue: " 2.4% ",
        },
        {
          title: "Average Rate",
          icon: "ri-briefcase-4-line",
          value: "$ 15.4",
          subvalue: " 2.4% ",
        },
      ],
    };
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Number of Sales</p>
              <h4 class="mb-0">{{ analytic.total_no_of_orders }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`ri-stack-line font-size-24`"></i>
            </div>
          </div>
        </div>

        <!-- <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{ data.subvalue }}
            </span>
            <span class="text-muted ml-2">From previous period</span>
          </div>
        </div> -->
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Total Items</p>
              <h4 class="mb-0">{{ analytic.yearly_sale[0].count }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`ri-briefcase-4-line font-size-24`"></i>
            </div>
          </div>
        </div>

        <!-- <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{ data.subvalue }}
            </span>
            <span class="text-muted ml-2">From previous period</span>
          </div>
        </div> -->
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Sales Revenue</p>
              <h4 class="mb-0">&#163;{{ analytic.yearly_sale[0].sum.toFixed(2) }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`ri-store-2-line font-size-24`"></i>
            </div>
          </div>
        </div>

        <!-- <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{ data.subvalue }}
            </span>
            <span class="text-muted ml-2">From previous period</span>
          </div>
        </div> -->
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Average Order Value</p>
              <h4 class="mb-0">&#163;{{ (Number(analytic.yearly_sale[0].sum) / Number(analytic.total_no_of_orders)).toFixed(2)}}</h4>
            </div>
            <div class="text-primary">
              <i :class="`ri-briefcase-4-line font-size-24`"></i>
            </div>
          </div>
        </div>

        <!-- <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{ data.subvalue }}
            </span>
            <span class="text-muted ml-2">From previous period</span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
