import Vue from "vue";
import Router from "vue-router";

// routes
import Login from "@/views/pages/account/routes.account/";
import Orders from "@/views/pages/orders/routes.orders/";
import ProfileManagement from "@/views/pages/profile-management/routes.profile/";
// import Customers from "@/views/pages/customers/routes.customers/";
// import Analytics from "@/views/pages/analytics/routes.analytics/";
import Dashboard from "@/views/pages/dashboard/routes.dashboard/";
// import Categories from "@/views/pages/categories/routes.categories/";
// import ProductType from "@/views/pages/product-type/routes.product-type/";
// import Products from "@/views/pages/products/routes.products/";
// import Attributes from "@/views/pages/attributes/routes.attributes/";
// import Transactions from "@/views/pages/transactions/routes.transactions/";

// import Quotation from "@/views/pages/quotation/routes.quotation/";

// import Purchase from "@/views/pages/purchase/routes.purchase/";

// import Applications from '@/views/Applications.vue'
// import Bussiness from '@/views/Bussiness.vue'
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },

  routes: [
    ...Login,
    {
      path: "/dashboard",
      component: () => import("@/views/layouts/main/"),
      children: [
        ...Dashboard,
        // ...Categories,
        // ...Attributes,
        // ...ProductType,
        // ...Products,
        ...Orders,
        ...ProfileManagement,
        // ...Transactions,
        // ...Customers,
        // ...Analytics,
        // ...Quotation,
        // ...Purchase

       
      ],
      
    },
    // {
    //   path: "/applications",
    //   name: 'applications',
    //   component: Applications
    // },
    // {
    //   path: "/bussiness",
    //   name: 'bussiness',
    //   component: Bussiness
    // },

    // Redirect to 404 page, if no match found
    {
      path: "/not-found",
      component: () => import(/* webpackChunkName: "404" */ "@/views/Error404"),
    },
    { path: "*", redirect: "/not-found" },
  ],
});

router.afterEach(() => {
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
